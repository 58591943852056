$font-stack: "Roboto", sans-serif;
$primary-color: #023047;
$secondary-color: #ffb703;
$tertiary-color: #219ebc;
$quaternary-color: #f2f2f2;
$quinary-color: #8ecae6;

a {
  color: $primary-color;
  text-decoration: none;
  &:hover {
    color: $secondary-color;
  }
}

body {
  background-color: $quaternary-color;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: $primary-color;
}
.wrap {
  background-color: #fdfdfd;
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
}
.categories {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 1rem 0;
  .category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      color: $primary-color;
    }
    h3 {
      margin: 0.5rem 0;
    }
    p {
      margin: 0;
    }
  }
}

.phones {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 1rem 0;
  .phone {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      color: #990e0e;
    }
    h3 {
      margin: 0.5rem 0;
    }
    p {
      margin: 0;
    }
  }
}

.head_overview {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 1rem;
  .mobileimg {
    padding: 2rem;
    text-align: center;
    display: grid;
    place-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .overview {
    color: #f2f2f2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    &_item {
      background-color: $tertiary-color;
      padding: 1rem;
      margin: 0.2rem;
      display: flex;
      justify-content: space-between;
    }
  }
}
.information {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  h3 {
    background-color: $tertiary-color;
    color: #fff;
    padding: 1rem;
    margin: 0;
    margin-top: 2rem;
  }
  .box {
    background-color: #fff;
    padding: 1rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
  }
}
.navbar {
  max-width: 1024px;

  margin: 0 auto;
  background-color: $quinary-color;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  margin-bottom: 2rem;
  &__logo {
    color: $primary-color;

    font-weight: 700;
    text-decoration: none;
  }
  &__links {
    display: flex;
    align-items: center;
    border: 1px solid $primary-color;
    gap: 2rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    li {
      list-style: none;
      a {
        color: $primary-color;
        text-decoration: none;
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
}

.footer {
  max-width: 1024px;
  margin: 0 auto;
  background-color: $primary-color;
  padding: 1rem 2rem;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  margin-top: 2rem;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      color: $secondary-color;
    }
  }
}
